<template>
  <div class="uploadImgCmpt">
    <!-- 上传组件 -->
    <input ref="input" type="file" @change="change" style="display:none" accept="image/*">
    <div class="uploadBox" @click="uploadImg">
      <!-- 未上传图片的封面 -->
      <div class="cover1" v-show="!loading && !uploaded && !picUrl">
        <img src="@/assets/image/upload.png" />
      </div>
      <!-- 上传中的loading -->
      <i class="cover2 el-icon-loading" v-show="loading && !uploaded"></i>
      <!-- 上传完毕的封面 -->
      <el-image class="img" fit="contain" :src="picUrl" v-show="uploaded || picUrl" :preview-src-list="[picUrl]"></el-image>
      <!-- 删除按钮 -->
      <!-- <i class="delete el-icon-delete" v-show="uploaded" @click.stop="deleteImg"></i> -->
      <!-- 只要picUrl有值就可以删除图片 -->
      <i class="delete el-icon-delete" v-show="picUrl && !isDelete" @click.stop="deleteImg"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: false, //是否正在上传图片
    uploaded: false, //是否上传完毕
    isDelete: false, // 控制是否有删除按钮  默认有删除按钮
    picUrl: '',
    coverDesc: {
      default: () => {
        return '图片格式只支持：PNG、JPEG、JPG、X-ICON'
      }
    }
  },
  data() {
    return {
      showDialog: false, //是否显示图片
    }
  },
  methods: {
    //点击上传图片
    uploadImg() {
      // 如果正在上传
      if (this.loading) return
      // 如果已经有图片则不让点击  需先删除再上传
      if (this.picUrl) return
      this.$refs['input'].click()
    },
    //实际上传图片逻辑
    change(e) {
      //兼容ie
      this.$emit('update:file', e.srcElement.files[0] || e.target.files[0])
      this.$emit('change')
      //防止上传相同的图片  change事件不触发
      this.$refs['input'].value = null
    },
    //删除图片
    deleteImg() {
      this.$confirm('删除该图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$emit('deleteImg')
      }).catch(() => {
        
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../assets/style/variable.scss';
.uploadBox {
  width: 280px;
  height: 180px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $textGray2;
  position: relative;
  .el-image {
    border: 1px dotted;
  }
  .cover1 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    background: rgb(251, 253, 255);
    i {
      font-size: 30px;
    }
    img {
      width: 100%;
      height: 100%;
      
    }
    span:last-child {
      color: $textGray1;
      font-size: 13px;
    }
  }
  .cover2 {
    position: absolute;
    font-size: 50px;
    .img {
      width: 100%;
      height: 100%;
    }
  }
  .delete {
    position: absolute;
    right: 5px;
    bottom: 5px;
    font-size: 25px;
    padding: 5px;
    border-radius: 50%;
    border: 1px solid black;
    color: black;
    z-index: 1;
  }
}
</style>